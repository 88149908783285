
require('./bootstrap');
require('./typehead-min');

$(function() {

        $('#worklogHours_2').on('change', function(e) {

            if (e.target.value == '' || e.target.value == '00:00'){

                $('#worklogCommessa_2').attr('disabled',true);
                $('#worklogActivity_2').attr('disabled',true);
                $('#worklogNotes_2').attr('disabled',true);
                $('#worklog_machine_2').attr('disabled',true);

            }else{
                
                $('#worklogCommessa_2').attr('disabled',false);
                $('#worklogActivity_2').attr('disabled',false);
                $('#worklogNotes_2').attr('disabled',false);
                $('#worklog_machine_2').attr('disabled',false);

            }

        });

        $('#worklogHours_3').on('change', function(e) {
            
            if (e.target.value == '' || e.target.value == '00:00'){

                $('#worklogCommessa_3').attr('disabled',true);
                $('#worklogActivity_3').attr('disabled',true);
                $('#worklogNotes_3').attr('disabled',true);
                $('#worklog_machine_3').attr('disabled',true);

            }else{
                
                $('#worklogCommessa_3').attr('disabled',false);
                $('#worklogActivity_3').attr('disabled',false);
                $('#worklogNotes_3').attr('disabled',false);
                $('#worklog_machine_3').attr('disabled',false);

            }

        });

        $('#worklogHours_4').on('change', function(e) {
            
            if (e.target.value == '' || e.target.value == '00:00'){

                $('#worklogCommessa_4').attr('disabled',true);
                $('#worklogActivity_4').attr('disabled',true);
                $('#worklogNotes_4').attr('disabled',true);
                $('#worklog_machine_4').attr('disabled',true);

            }else{
                
                $('#worklogCommessa_4').attr('disabled',false);
                $('#worklogActivity_4').attr('disabled',false);
                $('#worklogNotes_4').attr('disabled',false);
                $('#worklog_machine_4').attr('disabled',false);

            }

        });
          
        

    
   
});




